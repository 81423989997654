import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Title from 'blocks/Title';
import PageContent from 'blocks/PageContent';
import PageTabs from 'blocks/PageTabs';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Text from 'components/Text';
import Heading from 'components/Heading';

const Page = ({ data, pageContext }) => {
  const { item } = data;
  const { siblings } = pageContext;

  return (
    <Layout>
      <Seo
        image={item.image}
        title={item.seoTitle}
        description={item.seoDescription}
      />

      <Title image={item.image} alt={item.title} onlyImage />

      <PageContent>
        {siblings?.length > 1 && <PageTabs items={siblings} />}

        <Text weight="semi" color="primary-lighter" spacing="xs">
          {item.subtitle}
        </Text>

        <Heading as="h1" size="xl">
          {item.title}
        </Heading>

        <Text>
          <span dangerouslySetInnerHTML={{ __html: item.content }} />
        </Text>
      </PageContent>

      <ContactCta />
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.shape({
    item: PropTypes.shape({
      seoTitle: PropTypes.string.isRequired,
      seoDescription: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      image: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      subtitle: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    siblings: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
        tabTitle: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export const query = graphql`
  query pagesItem($slug: String!) {
    item: pages(slug: { eq: $slug }) {
      seoTitle
      seoDescription
      slug
      image {
        sharpImage: childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        publicURL
      }
      subtitle
      title
      content
    }
  }
`;

export default Page;
