import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import classNames from 'classnames';

import Link from 'components/Link/Link';

import classes from './PageTabs.scss';

function PageTabs({ items }) {
  const { pathname } = useLocation();

  return (
    <div className={classes.root}>
      {items.map((item) => (
        <Link
          className={classNames({
            [classes.link]: true,
            [classes.active]: pathname.includes(item.slug),
          })}
          key={item.slug}
          to={item.slug}
        >
          {item.tabTitle}
        </Link>
      ))}
    </div>
  );
}

PageTabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      tabTitle: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default PageTabs;
